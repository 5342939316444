<template>
  <h2 class="display-2">Current Chain Report</h2>
  <hr/>
  <div class="d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" style="width:10rem;height:10rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <template v-else>
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg></span>
      <input type="text" class="form-control" placeholder="Username" aria-label="Username" v-model="filter" />
    </div>
    <div class="overflow">
      <table class="table" >
        <thead>
          <tr style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
            onselectstart="return false;" onmousedown="return false;" unselectable="on">
            <th>#</th>
            <th v-for="(name,index) in header" :key="index+name" @click="sortButton(index,$event)">{{name.toString()}}</th>
          </tr>  
        </thead>
        <tbody>
          <tr v-for="(each,row_index) in data" :key="each">
            <td>{{row_index+1}}</td>
            <td v-for="(entry,col_index) in each" :key="entry" :data-label="header[col_index].valueOf()">{{entry}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>
<style lang="scss" scoped>
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/variables";
  @import "bootstrap/scss/mixins";
  @import "bootstrap/scss/utilities";
  .ascend::after{
    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-up-fill' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
  }
  .descend::after{
    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  }
  .overflow {
    overflow: auto;
    width:100%;
  }
</style>
<script type="text/javascript">
  import fetch from '@/fetch'
  class Sortable {
    constructor(string,sort) {
      this.string = string;
      this.sort = sort;
    }
    toString() {
      return this.string;
    }
    valueOf(){
      return this.sort;
    }
  }
  class Short {
    constructor(s,a){
      this.s = s;
      this.a = a;
    }
    toString() {
      return this.s;
    }
    valueOf(){
      return this.a;
    }
  }
  export default {
    components:{
    },
    data () {
      return {
        loading: false,
        msg: null,
        code:0,
        rawData:[],
        sortColumn:1,
        sortDesc:true,
        filter:''
      }
    },
    computed:{
      header () {
        return [
          'Name',
          new Short('Total Hits','Hits'),
          new Short('Average Respect','Avg Respect'),
          new Short('Last Seen (Hours)','Last Seen (Hrs)'),
        ];
      },
      data () {
        const n = v=>v?v:0;
        return this.rawData.map(([name,pid,hits,avg_res,hour_ago_seen])=>[
            new Sortable(`${name} [${pid}]`, name), //Name
            n(hits), // Total Hits
            avg_res, // 
            new Sortable(hour_ago_seen?`${hour_ago_seen} Hour${hour_ago_seen===1?'':'s'} Ago`:'Recent',hour_ago_seen)
        ]).filter(e=>{
          if (this.filter.length === 0)
            return true;
          return e[0].valueOf().toLowerCase().includes(this.filter.toLowerCase());
        }).sort((a,b)=>{
          if (this.sortColumn === null) return;
          const [x,y] = this.sortDesc?[b,a]:[a,b];
          let sort = this.sortColumn;
          const numSort = x[sort] - y[sort];
          if (numSort === 0)
            sort = 0;
          if (x[sort] && y[sort] && typeof x[sort].valueOf() === 'string' && typeof y[sort].valueOf() === 'string' )
            return x[sort].valueOf().localeCompare(y[sort].valueOf(),'en',{ sensitivity: 'base', ignorePunctuation: true });
          return numSort;
        }).map(row=>row.map((e,i)=>{
          if (typeof e === 'number')
            return e.toLocaleString([],i===2?{
               minimumFractionDigits:2,
               maximumFractionDigits:2
            }:{});
          if (e === null)
            return 'No Value';
          return e?.toString();
        }));
      },
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData();
    },
    methods: {
      async fetchData () {
        this.settings = {};
        this.code = this.msg = null;
        this.loading = true;
        fetch('/api/v1/chain/current',{method:'get'})
          .then(e=>{
            this.rawData = e.arr;
          }).catch(error=>{
            this.msg = {error};
          }).finally(()=>this.loading = false);
        // replace `getPost` with your data fetching util / API wrapper
      },
      sortButton(i,event) {
        const target = event.target.classList;
        if (this.sortColumn == i) {
          if (this.sortDesc) {
            target.remove('descend');
            this.sortDesc = false;
            this.sortColumn = null;
          } else {
            this.sortDesc = true;
            target.add('descend');
            target.remove('ascend');
          }
        } else {
          document.querySelectorAll('.ascend,.descend')
            .forEach(e=>e.classList.remove('ascend','descend'));
          target.add('ascend');
          this.sortColumn = i;
          if (this.sortDesc)
            this.sortDesc = false;
        }
      }
    }
  }
</script>
